import {
  ethers
} from "ethers";


import store from "@/store";

let chainIdAdress='address';
// const Contract = import(`@/wallet/${chainIdAdress}/LogicArms.logicArmsAddress.json`)

// import * as Contract from '@/wallet/address/LogicArms.logicArmsAddress.json'
// import * as Contract_bsc from '@/wallet/address_bsc/LogicArms.logicArmsAddress.json'



let ContractAddress;
let Abi;
async function start(){
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();
let res1= await signer.getChainId();;
let net_address='address'
if (res1=='1337') {
  net_address='address'
}else{
  net_address='address_bsc'
}


const { default: Contract } = await import(`@/wallet/${net_address}/LogicArms.logicArmsAddress.json`);
ContractAddress = Contract.address;
Abi = Contract.abi;


};




console.log(ethers.utils.toUtf8CodePoints('中国'));

import { approve, allowance} from "./tokenAuto";

export async function isAllowance() {
let isAllowance=await allowance(ContractAddress);

return isAllowance;
}
export async function toApprove() {
let isApprove=await approve(ContractAddress,Abi);
  return isApprove;
}

export async function extractArmsApi(roleNumber) {

await start();
store.commit("UpdateWalletWait",false);
  let max=9999;
  let min=1000;
  let num=Math.floor(Math.random()*(max-min))+min;
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
  let res = await myContract.extractArms(num,roleNumber);
  store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)+1);
  if(res.wait()){
    await res.wait();
    console.log("创建武器wait")
  }
  if(res){
      return res;
  }else{
    return false
  }

      
}

export async function getArmsList() {
  await start()
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  console.log("封装方法调用getArmsList")
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
  console.log("封装方法调用getArmsList")
  let res = await myContract.getArms();
  
  if(res){
      
      return res;
  }else{
    return false
  }
    
}
//获取武器维护费用列表
export async function getArmsLuckDrawPrice() {
await start()
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let res = await myContract.getArmsLuckDrawPrice();
res=Math.floor(ethers.utils.formatEther(res,6) * 10000) / 10000;
if(res){
    
    return res;
}else{
  return false
}
  
}

//销毁武器

export async function destroyArms(itemId) {
await start()
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let res = await myContract.destroyArms(itemId);
if(res.wait()){
  await res.wait();
}
if(res){
    
    return res;
}else{
  return false
}
  
}
