<template>
    <div class="grid">
		<div class="col-12 lg:col-6 xl:col-6">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
                            持有武器数量
                        </span>
						<div class="text-color-green font-medium text-xl">
							{{ArmListNow.length}}
						</div>
					</div>
					
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
        <div class="col-12 lg:col-6 xl:col-6">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
                            总共可以嵌入的宝石数量
                        </span>
						<div class="text-green-500 font-medium text-xl">
							{{MaxGemsCount}}
						</div>
					</div>
					
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
        
    </div>
	<div class="content_title mt-4 mb-4">
        创建武器
    </div>
	<div class="grid">
		<div class="col-12">
			<createArms @getArmsListEvent=getArmsListEvent />
			<p class="mt-2">当前抽取武器的价格为：<span class="text-warning text-bold">{{getArmPrice}} {{tokenName}}</span></p>
		</div>
	</div>
    <div class="content_title mt-4 mb-4">
        我的武器
    </div>
	<div class="grid mb-4">
		<div class="col-12">
			 <Button label="刷新我的武器列表" icon="iconfont icon-jiaose-" 
    			class="mr-2" @click="getArmsListEvent()"></Button>
		</div>
		<div class="col-12 mt-2">
			品质筛选：
		</div>
		<div class="col-12">
			<Button label="All" class="mr-2" @click="filterArms(0)" :class="filterNum == 0 ?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="1" class="mr-2" @click="filterArms(1)" :class="filterNum==1?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="2" class="mr-2" @click="filterArms(2)" :class="filterNum==2?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="3" class="mr-2" @click="filterArms(3)" :class="filterNum==3?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="4" class="mr-2" @click="filterArms(4)" :class="filterNum==4?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="5" class="mr-2" @click="filterArms(5)" :class="filterNum==5?'active':'p-button-raised p-button-secondary'"></Button>
		</div>
	</div>
	<div class="grid ">
			<div class="col-12 lg:col-6 xl:col-3" v-for="item in ArmListNow" v-if="ArmListNow">
				<div class="card mb-0 text-center imgCard text-left">
					<img :src='require("@/assets/img/arm/monkey/"+item.quality.toNumber()+".jpg")' class="cover_img" v-if="item.number.toNumber()==1"/>
					<img :src='require("@/assets/img/arm/pig/"+item.quality.toNumber()+".jpg")' class="cover_img" v-else-if="item.number.toNumber()==2"/>
					<img :src='require("@/assets/img/arm/shazeng/"+item.quality.toNumber()+".jpg")' class="cover_img" v-else-if="item.number.toNumber()==3"/>
					<div class="content flex justify-content-between pb-1">
						<h5 class="title">
							{{item.name}}
						</h5>

						<p>等级：{{item.quality.toNumber()}}</p>
					
					</div>
					
					<div class="imgBoxShow">
						<div style="width:100%">
							<div class="iconBox mb-4">
							
								<destroyArmsModal :info="item.itemId.toNumber()" @getArmsListEvent="getArmsListEvent"/>
								
								<tarnsferArm :roleInfo="item" @tarnferArmEvent="tarnferArmEvent" />
							
								<sellArmModal :roleInfo="item" @sellArmEvent="sellArmEvent"/>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			
		</div>
</template>


<script>
import { defineComponent,ref,store,computed} from 'vue';

import createArms from "../components/Create/arms.vue";
import {getArmsList,getArmsLuckDrawPrice} from "../wallet/LogicArms";
import sellArmModal from "../components/Modal/sellArmModal.vue";
import tarnsferArm from "../components/Modal/tarnsferArm.vue";
import destroyArmsModal from "../components/Modal/destroyArms.vue";
import {sortArr} from "../hooks/getAttr";
export default defineComponent({
    name:"armsPage",
	
 	components: {
		createArms,
		sellArmModal,
		tarnsferArm,
		destroyArmsModal
	},
	setup(){
		let filterNum=ref(0);
		let ArmList=ref('');
		let ArmListNow=ref('');
		let ArmMaxRank=ref('');
		let MaxGemsCount=ref(0);
		let getArmPrice=ref('');
		const getArmsListEvent=()=>{
			ArmList.value='';
			getArmsList().then(res=>{
				console.log("获取地址下的所有武器22",res)
				ArmList.value=res;
				ArmListNow.value=res;
				sortArr(res,0).then(res=>{
					ArmListNow.value = res;
				});
				let arrs = res.map((value, index, array) => {
					return value.quality
				})
				//ArmMaxRank.value=Math.max(...arrs);
				MaxGemsCount.value=arrs.reduce(( acc, cur ) => {
					return Number(acc) + Number(cur)
				}, 0);
			}).catch(error=>{
				console.log("调用方法失败")
			})
		}
		getArmsListEvent();

		//上架武器组件调用
		const sellArmEvent = () => {
			
			getArmsListEvent();
		}

		//赠送武器组件调用
		const tarnferArmEvent = () => {
			getArmsListEvent();
		}

	

		//筛选武器
		const filterArms=(quality)=>{
			filterNum.value=quality;
			if(quality!=0){
				let filterArmsArr=ArmList.value.filter((value,key,arr) => {
					return value.quality.toNumber() == quality? true :  false; 
				})
				ArmListNow.value=filterArmsArr;
			}else{
				ArmListNow.value=ArmList.value;
			}
			
		}

		//抽取武器价格
		getArmsLuckDrawPrice().then(res=>{
			getArmPrice.value=res;
		})
		return{
			ArmList,
			ArmListNow,
			getArmsListEvent,
			tarnferArmEvent,
			sellArmEvent,
			filterNum,
			filterArms,
			ArmMaxRank,
			getArmPrice,
			MaxGemsCount
		}
	}
	
})
</script>
<style lang="scss" scoped>
.imgCard{
	position: relative;
	img{
	
		height: -webkit-fill-available;
		
	}
	.imgBoxShow:deep{
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		top: 0;
		display: flex;
		align-items: center;

		padding: 0 20px;
		display: none;

		.iconBox {
			height: auto;
			display: flex;
			justify-content: space-between;

			.iconfont {
				display: block;
				background: $themeColor;
				color: #16202F;
				padding: 10px;
				border-radius: $radisIconbtn;

				// margin: 0 8px;
				&:last-child {
					margin: 0;
				}

				&:first-child {
					margin: 0;
				}
			}
		}

		.functionBtn {}
	}
	&:hover {
		.imgBoxShow {
			display: flex;
		}
	}
}
</style>