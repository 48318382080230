import {
    ethers
} from "ethers";

import store from "@/store";

console.log(ethers.utils.toUtf8CodePoints('中国'));

let ContractAddress;
let Abi;

async function start(){
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  let res1= await signer.getChainId();;
    let address='address'
    if (res1=='1337') {
      address='address'
    }else{
        address='address_bsc'
    }

   
    const { default: Contract } = await import(`@/wallet/${address}/LogicTransferFrom.logicTransferFromAddress`);
    ContractAddress = Contract.address;
    Abi = Contract.abi;


};
import { approve, allowance} from "./tokenAuto";


export async function isAllowance() {
    let isAllowance=await allowance(ContractAddress);
   
    return isAllowance;
}
export async function toApprove() {
    let isApprove=await approve(ContractAddress,Abi);
    return isApprove;
}


export async function roleTransferFrom(recipient,tokenId) {
   console.log("角色转让地址",recipient);
   console.log("角色转让id",tokenId)
   await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
   
    let address= await signer.getAddress();
    let res = await myContract.roleTransferFrom(recipient,tokenId);
    if(res.wait()){
        await res.wait();
       
      }
    if(res){
        
        return res;
    }else{
      return false
    }
}

export async function armsTransferFrom(recipient,tokenId) {
  console.log("武器转让地址",recipient);
  console.log("武器转让id",tokenId)
  await start();
 const provider = new ethers.providers.Web3Provider(window.ethereum)
   const signer = provider.getSigner();
   
   let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
  
   let address= await signer.getAddress();
   let res = await myContract.armsTransferFrom(recipient,tokenId);
   if(res.wait()){
       await res.wait();
      
     }
   if(res){
       
       return res;
   }else{
     return false
   }
}

export async function gemsTransferFrom(recipient,tokenId) {
  console.log("武器转让地址",recipient);
  console.log("武器转让id",tokenId)
  await start();
 const provider = new ethers.providers.Web3Provider(window.ethereum)
   const signer = provider.getSigner();
   
   let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
  
   let address= await signer.getAddress();
   let res = await myContract.gemsTransferFrom(recipient,tokenId);
   if(res.wait()){
       await res.wait();
      
     }
   if(res){
       
       return res;
   }else{
     return false
   }
}



 

