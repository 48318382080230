<template>
    <Button label="创建悟空武器" icon="iconfont icon-jiaose-" 
    class="mr-2" @click="createArmsEvent(1)" :disabled="$store.getters.getXyjTokenBalance<=0"></Button>
     <Button label="创建猪八戒武器" icon="iconfont icon-jiaose-" 
    class="mr-2 mt-2" @click="createArmsEvent(2)" :disabled="$store.getters.getXyjTokenBalance<=0"></Button>
     <Button label="创建沙悟净武器" icon="iconfont icon-jiaose-" 
    class="mr-2 mt-2" @click="createArmsEvent(3)" :disabled="$store.getters.getXyjTokenBalance<=0"></Button>
    <p v-if="$store.getters.getXyjTokenBalance<=0" class="text-danger">
        (Tips:当前余额不足)
    </p>
</template>


<script>

import { defineComponent,ref,computed} from 'vue';
import { useStore } from "vuex";
import * as ArmsApi from "../../wallet/LogicArms";
import {getBlbalance} from "../../wallet/tokenAuto";
export default defineComponent({
    name:"createRole",
    emits: ["getArmsListEvent"],
    setup(props, {
      emit
    }){
        const store = useStore();
        //创建角色
        const createArmsEvent=(roleNumber)=>{
             store.commit("UpdateWalletWait",true);
             ArmsApi.extractArmsApi(roleNumber).then(res=>{
                 console.log("创建武器成功",res)
                   store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                 getBlbalance();
                 emit('getArmsListEvent')
             }).catch(error=>{
                   store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                 console.log("创建武器失败")
             })
        }

        //检查授权情况   
        const isArmTokenAuth=()=>{
             ArmsApi.isAllowance().then(res=>{
                 console.log("武器组件调用检查授权",res);
                 if(res>0){
                      store.commit("UpdateIsArmTokenAuth",true)
                     return true
                 }else{
                     store.commit("UpdateIsArmTokenAuth",false)
                     return false;
                 }
             })
        }
        //isArmTokenAuth()
        //授权武器合约
        const authTips=ref("当前暂未授权武器合约,点击授权");
        const isAuthNow=ref(false);
        const approveArms=()=>{
            isAuthNow.value=true;
            authTips.value="正在唤起钱包授权";
            ArmsApi.toApprove().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNow.value=false;
                if(res){
                     
                    store.commit("UpdateIsArmTokenAuth",true)
                    return true
                }else{
                    store.commit("UpdateIsArmTokenAuth",false)
                    return false;
                }
            })
        }

      
        return{
            isArmTokenAuth,
          createArmsEvent,
          approveArms,
          authTips
        }
    },
    
    
})
</script>
